import { Button, Col, Divider, Form, Modal, Row, Typography } from 'antd';
import { graphql, navigate } from 'gatsby';
import React, { useContext, useMemo, useState } from 'react';
import { CartItem } from '../components/Cart/CartItem';
import { Layout } from '../components/Layout/Layout';
import { ROUTES } from '../constants/routes';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import { useShoppingCart } from '../context/ShoppingCartContext';
import { ShoppingCartProps } from '../types';
import useMediaQuery from '../utils/hooks/useMediaQuery';
import './shopping-cart.less';

export const ShoppingCart = (data: ShoppingCartProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isMobile } = useMediaQuery();
  const { Title, Text } = Typography;
  const { cartItems, cartQuantity, removeFromCart } = useShoppingCart();

  const INFO_TEXT_DESKTOP = ['', '', 'PRODUKT', 'CENA', 'ILOŚĆ', 'KWOTA'];
  const INFO_TEXT_MOBILE = ['PRODUKT', 'KWOTA'];
  const courses = data.data.allStrapiCourse.nodes;
  const coursesStripeIds = courses.map(course => course.stripeId);
  const products = data.data.allStrapiProduct.nodes;
  const coursesAndProducts = [...courses, ...products];

  const renderInfoText = () => {
    return (isMobile ? INFO_TEXT_MOBILE : INFO_TEXT_DESKTOP).map(
      (text, index) => (
        <Text key={index} className="info-text">
          <div>{text}</div>
        </Text>
      )
    );
  };

  const totalPrice = useMemo(() => {
    if (typeof cartItems !== 'undefined') {
      return cartItems.reduce((total, cartItem) => {
        const item = coursesAndProducts.find(i => i.id === cartItem.id);
        return total + (item?.price || 0) * cartItem.quantity;
      }, 0);
    }
  }, [cartItems]);

  const cartItemsData = cartItems?.map(item => {
    return {
      price: item.stripeId,
      quantity: item.quantity,
    };
  });

  const {
    state: { authenticated },
  } = useContext(GlobalStateContext);

  const handleCheckout = () => {
    if (!authenticated) {
      setIsModalOpen(true);
      return;
    }
    const userId = localStorage?.getItem('userid');
    const CHECKOUT_URL = `${process.env.STRAPI_API_URL}/api/create-checkout-session`;

    fetch(CHECKOUT_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        items: cartItemsData,
        userId: userId,
        coursesStripeIds,
      }),
    })
      .then(res => {
        if (res.ok) return res.json();
        return res.json().then(json => Promise.reject(json));
      })
      .then(({ url }) => {
        window.location = url;
        cartItems.forEach(item => removeFromCart(item.id));
      })
      .catch(e => {
        console.error(e.error);
      });
  };

  return (
    <Layout>
      <section className="section-content-container">
        <Row className="cart-page">
          <Title className="section-title-cart" level={3}>
            Koszyk
          </Title>
          {cartQuantity > 0 ? (
            <Col className="cart-container" span={24}>
              <div className="cart-container-content">
                <Title className="cart-container-order-title" level={4}>
                  Twoje zamówienie
                </Title>
                <div className="cart-container-info-text">
                  {renderInfoText()}
                </div>
                <Divider className="divider" />
                <div>
                  {cartItems?.map(item => (
                    <CartItem
                      key={item.id}
                      {...item}
                      coursesAndProducts={coursesAndProducts}
                    />
                  ))}
                </div>
                <Divider className="divider" />
                <Text className="total-price-container">
                  <Text>
                    Suma: {''}
                    {totalPrice}
                    {''} zł. (zawiera VAT)
                  </Text>
                </Text>
                <Form
                  className="order-button-container"
                  action="/create-checkout-session"
                  method="POST"
                >
                  <Button type="dashed" onClick={handleCheckout}>
                    <Text>Przejdź do kasy</Text>
                  </Button>
                </Form>
              </div>
            </Col>
          ) : (
            <Col span={24} className="cart-container">
              <Text className="empty-cart-text">Twój koszyk jest pusty!</Text>
              <Button type="dashed" onClick={() => navigate(ROUTES.PRODUCTS)}>
                <Text>Nasze produkty</Text>
              </Button>
            </Col>
          )}
        </Row>
        <Modal
          visible={isModalOpen}
          okText="Zaloguj się"
          onOk={() => navigate(ROUTES.SIGN_IN)}
          cancelText="Zamknij"
          onCancel={() => setIsModalOpen(false)}
          cancelButtonProps={{ type: 'ghost', className: 'cancel-modal-btn' }}
          closable={false}
        >
          <Text>Musisz być zalogowany, aby kupić produkt.</Text>
        </Modal>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allStrapiProduct {
      nodes {
        id
        price
        name
        picture {
          localFile {
            url
          }
        }
      }
    }
    allStrapiCourse {
      nodes {
        name
        id
        stripeId
        price
        picture {
          localFile {
            url
          }
        }
      }
    }
  }
`;

export default ShoppingCart;
