import * as React from 'react';
import './CartItem.less';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import { Button, Image, Typography } from 'antd';
import useMediaQuery from '../../utils/hooks/useMediaQuery';

type CartItemProps = {
  id: any;
  quantity: number;
  coursesAndProducts: any;
};

export const CartItem = (props: CartItemProps) => {
  const { Text } = Typography;
  const { isMobile } = useMediaQuery();
  const { removeFromCart } = useShoppingCart();
  const item = props.coursesAndProducts.find(i => i.id === props.id);
  if (!item) return null;

  return (
    <>
      <div className="container-cart-item">
        <Button
          onClick={() => removeFromCart(props.id)}
          type="link"
          className="remove-item-button"
        >
          X
        </Button>
        <div className="container-image">
          <Image
            preview={false}
            src={item.picture.localFile.url}
            alt="product"
            className="item-image"
          />
        </div>
        {!isMobile ? (
          <>
            <Text className="item-title">{item.name}</Text>
            <Text className="item-price">{item.price} zł.</Text>
            <Text className="item-quantity">{props.quantity} szt.</Text>
            <Text className="item-price">{item.price} zł.</Text>
          </>
        ) : (
          <>
            <div className="item-container">
              <Text className="item-title-tablet">{item.title}</Text>
              <Text className="item-quantity-tablet"> x{props.quantity} </Text>
            </div>
            <div>
              <Text className="item-price-tablet">{item.price} zł.</Text>
            </div>
          </>
        )}
      </div>
    </>
  );
};
